
import universal, { setHasBabelPlugin } from '/opt/build/repo/node_modules/react-static/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../src/pages/404.js'), universalOptions)
const t_1 = universal(import('../src/pages/blog/index.js'), universalOptions)
const t_2 = universal(import('../src/pages/post'), universalOptions)
const t_3 = universal(import('../src/pages/contact/index.js'), universalOptions)
const t_4 = universal(import('../src/pages/disclaimer/index.js'), universalOptions)
const t_5 = universal(import('../src/pages/home/index.js'), universalOptions)
const t_6 = universal(import('../src/pages/lawyer/index.js'), universalOptions)
const t_7 = universal(import('../src/pages/lawyers/index.js'), universalOptions)
const t_8 = universal(import('../src/pages/lawyer'), universalOptions)
const t_9 = universal(import('../src/pages/page/index.js'), universalOptions)
const t_10 = universal(import('../src/pages/post/index.js'), universalOptions)
const t_11 = universal(import('../src/pages/practicearea/index.js'), universalOptions)
const t_12 = universal(import('../src/pages/practiceareas/index.js'), universalOptions)
const t_13 = universal(import('../src/pages/home'), universalOptions)
const t_14 = universal(import('../src/pages/page'), universalOptions)
const t_15 = universal(import('../src/pages/practiceareas'), universalOptions)
const t_16 = universal(import('../src/pages/practicearea'), universalOptions)


// Template Map
export default [
  t_0,
t_1,
t_2,
t_3,
t_4,
t_5,
t_6,
t_7,
t_8,
t_9,
t_10,
t_11,
t_12,
t_13,
t_14,
t_15,
t_16
]
