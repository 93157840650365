import React, { Component } from 'react';
import { Link } from '@reach/router';
import logo from './logo.svg';
import logoSymbol from './logo-symbol.svg';
import angledLogo from './angled-logo.svg';

// {
//   name: 'Frequent Questions',
//     url: '/faq',
// }
const pages = [{
  name: 'Practice Areas',
  url: '/practice-areas',
}, {
  name: 'Our Attorneys',
  url: '/lawyers',
}, {
  name: 'About Us',
  url: '/about',
}, {
  name: 'Legal Blog',
  url: '/blog',
}, {
  name: 'Community Work',
  url: '/community',
}, {
  name: 'Contact Us',
  url: '/contact',
}];

export default class Sidebar extends Component {
  state = {
    openedSidebar: false
  };

  closeSidebar = () => {
    this.setState({ openedSidebar: false });
  };

  openSidebar = () => {
    this.setState({ openedSidebar: true });
  };

  render() {
    let { openedSidebar } = this.state;

    const links = pages.map((page, pageIndex) => (<li key={pageIndex}>
      <Link to={page.url} onClick={() => this.closeSidebar()}>{page.name}</Link>
    </li>));

    return (<React.Fragment>
      <div className="sidebar-opener" onClick={() => this.openSidebar()}>
        <img src={logoSymbol} alt="" className="logo" />
      </div>
      <div className={openedSidebar ? 'main-sidebar on' : 'main-sidebar'}>
        <Link to="#" className="close-sidebar" onClick={e => { e.preventDefault();this.closeSidebar() }}>&times;</Link>
        <Link to="/" className="logo">
          <img src={logo} alt="MJS Logo" />
        </Link>
        <ul className="menu list-unstyled">
          {links}
        </ul>
        <section className='footer'>
          <p>© { new Date().getFullYear() } Moore, Johnson & Saraniti Law Firm</p>
          <ul className="list-unstyled list-inline">
            <li>
              <Link to="/disclaimer">Site Disclaimer</Link>
            </li>
          </ul>
        </section>
        <div className="angledLogo">
          <img src={angledLogo} role="presentation" />
        </div>
      </div>
    </React.Fragment>);
  }
}
