import React from 'react';
import { Root, Routes } from 'react-static';
import Helmet from 'react-helmet';
import Sidebar from './components/Sidebar';
import './flexboxgrid.css';
import './app.sass';

const head = {
  titleTemplate: '%s | Moore, Johnson and Saraniti Law Firm P.A.',
  meta: [
    { name: 'description', content: 'Scaffold a react universal app with racket' },
    { charset: 'utf-8' },
    { property: 'og:site_name', content: 'Racket' },
    { property: 'og:image', content: '/logo.jpg' },
    { property: 'og:locale', content: 'en_US' },
    { property: 'og:title', content: 'Moore, Johnson and Saraniti Law Firm P.A.' },
    { property: 'og:description', content: 'Moore, Johnson and Saraniti Law Firm P.A.' },
    { property: 'og:card', content: 'summary' },
    { property: 'og:site', content: 'Moore, Johnson and Saraniti Law Firm P.A.' },
    { property: 'og:creator', content: 'Moore, Johnson and Saraniti Law Firm P.A.' },
    { property: 'og:image:width', content: '200' },
    { property: 'og:image:height', content: '200' },
  ],
};

export default ({ children, route }) => <Root>
  <Sidebar />
  <Helmet {...head} />
  <Routes />
</Root>;
